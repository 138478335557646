exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-3-rozwoj-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/blog/3-rozwoj/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-3-rozwoj-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-3-za-chwile-rusza-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/blog/3-za-chwile-rusza/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-3-za-chwile-rusza-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-5-konkurs-wyniki-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/blog/5-konkurs-wyniki/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-5-konkurs-wyniki-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-6-konkurs-nowa-edycja-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/blog/6-konkurs-nowa-edycja/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-6-konkurs-nowa-edycja-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-7-konkurs-koniec-1-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/blog/7-konkurs-Koniec-1/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-7-konkurs-koniec-1-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-8-konkurs-wyniki-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/blog/8-konkurs-Wyniki/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-8-konkurs-wyniki-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-9-konkurs-nowa-edycja-2024-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/blog/9-konkurs-nowa-edycja-2024/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-buildhome-repo-src-blog-9-konkurs-nowa-edycja-2024-index-mdx" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konkurs-2-js": () => import("./../../../src/pages/konkurs2.js" /* webpackChunkName: "component---src-pages-konkurs-2-js" */),
  "component---src-pages-konkurs-js": () => import("./../../../src/pages/konkurs.js" /* webpackChunkName: "component---src-pages-konkurs-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-o-cechu-js": () => import("./../../../src/pages/o_cechu.js" /* webpackChunkName: "component---src-pages-o-cechu-js" */),
  "component---src-pages-statut-js": () => import("./../../../src/pages/statut.js" /* webpackChunkName: "component---src-pages-statut-js" */),
  "component---src-pages-zarzad-js": () => import("./../../../src/pages/zarzad.js" /* webpackChunkName: "component---src-pages-zarzad-js" */)
}

